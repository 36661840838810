import { ContractType } from "../dataContracts"

export type Appeal = {
    id: string,
    contractId: string,
    contractNumber: string,
    number: string,
    firstMessage?: AppealMessage,
    lastMessage?: AppealMessage,
    state: AppealState,
    date: string,
    topic: string
}

export type AppealMessage = {
    content: string,
    date: string
}

export type Topic = {
    id: string,
    name: string
}

export type Message = {
    id: string,
    date: string,
    content: string,
    source: MessageSource,
    files: FileDescription[]
}

export type FileDescription = {
    id: number,
    name: string,
    created: Date,
    extension: string,
    downloading: boolean
}

export enum MessageSource {
    user,
    other
}

export type Contract = {
    contractId: string,
    number: string,
    type: ContractType
}

export enum AppealState {
    None,
    Sended,
    Replied,
    Closed
}

export const appealStateParse = (state: AppealState): string => {
    switch (state) {
        case AppealState.None: return 'Новое'
        case AppealState.Sended: return 'Отправлено'
        case AppealState.Replied: return 'Получен ответ'
        case AppealState.Closed: return 'Закрыто'
    }
}