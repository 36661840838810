import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { store } from "../index"
import ProxyFactory from "@/apiProxies/proxyFactory"
import { Appeal, Contract, Message, Topic } from "@/apiProxies/dataContracts/appeals"

@Module({ store, name: 'appeals', namespaced: true, dynamic: true, stateFactory: true })
export class AppealsModule extends VuexModule {

    private _appeal: Appeal | undefined
    private _topics: Array<Topic> = []
    private _appeals: Array<Appeal> = []
    private _messages: Array<Message> = []

    public get appeal(): Appeal | undefined {
        return this._appeal
    }

    public get topics(): Topic[] {
        return this._topics
    }

    public get appeals(): Appeal[] {
        return this._appeals
    }

    public get messages(): Message[] {
        return this._messages
    }

    @Mutation
    setAppeal(value: Appeal | undefined): void {
        this._appeal = value
    }

    @Mutation
    setTopics(value: Topic[]): void {
        this._topics = value
    }

    @Mutation
    setAppeals(value: Appeal[]): void {
        this._appeals = value
    }

    @Mutation
    setMessages(value: Message[]): void {
        this._messages = value
    }

    @Action
    public async loadTopics(cotnract: string): Promise<void> {
        this.setTopics([])

        const result = await ProxyFactory.createAppealsPresenterProxy()

        const value = await result.getTopics(cotnract)

        this.setTopics(value)
    }

    @Action
    public async loadAppeal(appealId: string): Promise<void> {
        this.setAppeal(undefined)

        const result = await ProxyFactory.createAppealsPresenterProxy()

        const value = await result.getAppeal(appealId)

        this.setAppeal(value)
    }

    @Action
    public async loadAppeals(): Promise<void> {
        const result = await ProxyFactory.createAppealsPresenterProxy()

        const value = await result.getAppeals()

        this.setAppeals(value)
    }

    @Action
    public async loadMessages(appealId: string): Promise<void> {
        const result = await ProxyFactory.createAppealsPresenterProxy()

        const value = await result.getMessages(appealId)

        this.setMessages(value)
    }

    @Action
    public async createAppeal(value: { contract: string, topic: string }): Promise<string> {
        const result = await ProxyFactory.createAppealsManagerProxy()

        return await result.create(value.contract, value.topic)
    }

    @Action({ rawError: true })
    public async deleteAppeal(appealId: string): Promise<void> {
        const result = await ProxyFactory.createAppealsManagerProxy()

        await result.delete(appealId)
    }

    @Action
    public async createMessage(body: { appealId: string, content: string, files: File[] }): Promise<void> {
        const result = await ProxyFactory.createAppealsManagerProxy()

        await result.createMessage(body.appealId, body.content, body.files)
    }

    @Action
    public async getContract(number: string): Promise<Contract> {
        const result = await ProxyFactory.createAppealsPresenterProxy()

        return await result.getContract(number)
    }
}

export const appealsModule = getModule(AppealsModule)